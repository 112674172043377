<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {
      dataurl:"/vehicle-body",
      baseroute:"vehicle-body",
      title:"Body Kendaraan",
      fields : [
        { key: 'name', label: 'Nama Body', type: 'input', rules:'required' },
        { key: 'is_check', label: 'Pengecekan', type: 'bool', rules:'required' },
      ]
    }
  },
}
</script>